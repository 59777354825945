<template>
    <div class="template_wrap">
      <div class="banner">
        <div class="tab-wrap">
          <div class="tab" :class="{choose: tabIndex == 0}" @click="tabIndex = 0">
            <div class="icon"></div>热门模板
          </div>
          <div class="tab" :class="{choose: tabIndex == 1}" @click="tabIndex = 1">
            <div class="icon"></div>节日推荐
          </div>
        </div>
        <div class="tab-content">
          <div v-show="tabIndex == 0" class="template-list">
            <div class="hotTemplate"
                 v-for="(item,index) of hotTemplate"
                 @click="$router.push('OwnWorkbench/OwnActivity')"
                 :key="index">
              <div class="template-info">
                <div class="template-name">{{ item.name }}</div>
                <div class="template-price">
                  <span class="free">{{  item.free }}</span>
                  <span class="price">￥{{ item.price }}元</span>
                  <span class="type">{{  item.type }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="tabIndex == 1" class="template-list">
            <div class="hotTemplate holidayTemplate"
                 v-for="(item,index) of holidayTemplate"
                 @click="$router.push('OwnWorkbench/OwnActivity')"
                 :key="index">
              <div class="template-info">
                <div class="template-name">{{ item.name }}</div>
                <div class="template-price">
                  <span class="free">{{  item.free }}</span>
                  <span class="price">￥{{ item.price }}元</span>
                  <span class="type">{{  item.type }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="classify-wrap">
        <div class="classify-content">
          类别
          <div class="classify" :class="{choose: classIndex == index}" @click="classIndex = index"
               v-for="(item, index) of classList" :key="index">{{ item }}</div>
        </div>
      </div>
      <div class="templateList">
        <div class="hotTemplate"
             v-for="(item) of template[classIndex]"
             @click="$router.push('OwnWorkbench/OwnActivity')"
             :key="item.imgId"
            :style="{backgroundImage: `url(https://wxyx-xcx.obs.cn-south-1.myhuaweicloud.com/lfPC/template/${item.imgId}.png)`}"
        >
          <div class="template-info">
            <div class="template-name">{{ item.name }}</div>
            <div class="template-price">
              <span class="free">{{  item.free }}</span>
              <span class="price">￥{{ item.price }}元</span>
              <span class="type">{{  item.type }}</span>
            </div>
          </div>
        </div>
      </div>
      <Foot />
    </div>
</template>

<script>
import Foot from '@/components/footer.vue'
export default {
  name: 'templates',
  components: {
    Foot
  },
  data () {
    return {
      tabIndex: 0,
      hotTemplate: [
        {
          name: '接力跑挑战赛',
          type: '接力跑竞赛',
          price: '23',
          free: '免费'
        },
        {
          name: '游泳比赛',
          type: '游泳竞赛',
          price: '23',
          free: '免费'
        },
        {
          name: '数字谜盘',
          type: '数字谜盘',
          price: '20',
          free: '限免'
        },
        {
          name: '南北水饺大作战',
          type: '南北大比拼',
          price: '30',
          free: '限免'
        },
        {
          name: '点球大战',
          type: '点球大战',
          price: '15',
          free: '免费'
        }
      ],
      holidayTemplate: [
        {
          name: '温暖回家路',
          type: '温暖回家路',
          price: '15',
          free: '限免'
        },
        {
          name: '最美集赞',
          type: '最美集赞',
          price: '18',
          free: '免费'
        },
        {
          name: '红包雨',
          type: '红包雨',
          price: '10',
          free: '限免'
        },
        {
          name: '世界杯竞猜',
          type: '世界杯竞猜',
          price: '12',
          free: '免费'
        },
        {
          name: '现场抽奖',
          type: '现场抽奖',
          price: '25',
          free: '限免'
        }
      ],
      classList: ['活动抽奖', '分享裂变', '红包活动', '秒杀拼团', '投票', '答题', '游戏', '现场活动', '节日'],
      classIndex: 0,
      template: {
        0: [ // 活动抽奖
          {
            imgId: '0',
            name: '宝箱送福',
            type: '开宝箱',
            price: '80',
            free: '免费'
          },
          {
            imgId: '1',
            name: '幸运大抽奖',
            type: '九宫格',
            price: '45',
            free: '限免'
          },
          {
            imgId: '2',
            name: '砸金蛋赢好礼',
            type: '砸金蛋',
            price: '45',
            free: '免费'
          },
          {
            imgId: '3',
            name: '转转有惊喜',
            type: '大转盘',
            price: '65',
            free: '限免'
          },
          {
            imgId: '11',
            name: '好运刮一刮',
            type: '刮刮乐',
            price: '80',
            free: '免费'
          },
          {
            imgId: '12',
            name: '好运当投',
            type: '投壶',
            price: '60',
            free: '免费'
          },
          {
            imgId: '13',
            name: '趣玩潮前看',
            type: '潮前看',
            price: '45',
            free: '限免'
          },
          {
            imgId: '14',
            name: '好运一整年',
            type: '红包雨',
            price: '40',
            free: '免费'
          }
        ],
        1: [ // 分享裂变
          {
            imgId: '15',
            name: '海报拉新有礼',
            type: '海报分享',
            price: '35',
            free: '免费'
          },
          {
            imgId: '23',
            name: '红包树',
            type: '红包树',
            price: '52',
            free: '限免'
          },
          {
            imgId: '22',
            name: '温暖回家路',
            type: '温暖回家路',
            price: '75',
            free: '免费'
          },
          {
            imgId: '26',
            name: '最美集赞',
            type: '最美集赞',
            price: '50',
            free: '限免'
          },
          {
            imgId: '21',
            name: '一起养小兔',
            type: '养宠物',
            price: '68',
            free: '免费'
          }
        ],
        2: [ // 红包活动
          {
            imgId: '23',
            name: '红包树',
            type: '红包树',
            price: '52',
            free: '限免'
          },
          {
            imgId: '24',
            name: '现场抽奖',
            type: '现场抽奖',
            price: '25',
            free: '限免'
          },
          {
            imgId: '14',
            name: '好运一整年',
            type: '红包雨',
            price: '40',
            free: '免费'
          },
          {
            imgId: '19',
            name: '竞猜赢大奖',
            type: '足球竞猜',
            price: '99',
            free: '限免'
          },
          {
            imgId: '20',
            name: '答题有礼',
            type: '答题',
            price: '78',
            free: '免费'
          }
        ],
        3: [ // 秒杀拼团
          {
            imgId: '14',
            name: '好运一整年',
            type: '红包雨',
            price: '40',
            free: '免费'
          },
          {
            imgId: '24',
            name: '现场抽奖',
            type: '现场抽奖',
            price: '68',
            free: '免费'
          },
          {
            imgId: '11',
            name: '好运刮一刮',
            type: '刮刮乐',
            price: '80',
            free: '免费'
          },
          {
            imgId: '20',
            name: '答题有礼',
            type: '答题',
            price: '78',
            free: '免费'
          }
        ],
        4: [ // 投票
          {
            imgId: '26',
            name: '最美集赞',
            type: '最美集赞',
            price: '50',
            free: '限免'
          },
          {
            imgId: '28',
            name: '南北饺子大比拼',
            type: '南北大比拼',
            price: '66',
            free: '限免'
          },
          {
            imgId: '24',
            name: '现场抽奖',
            type: '现场抽奖',
            price: '68',
            free: '免费'
          }
        ],
        5: [ // 答题
          {
            imgId: '20',
            name: '答题有礼',
            type: '答题',
            price: '78',
            free: '免费'
          },
          {
            imgId: '18',
            name: '拼拼乐',
            type: '拼拼乐',
            price: '88',
            free: '限免'
          },
          {
            imgId: '29',
            name: '数字谜盘',
            type: '数字谜盘',
            price: '66',
            free: '免费'
          }
        ],
        6: [ // 游戏
          {
            imgId: '17',
            name: '大富翁寻宝',
            type: '大富翁',
            price: '38',
            free: '免费'
          },
          {
            imgId: '18',
            name: '趣味拼拼乐',
            type: '拼拼乐',
            price: '42',
            free: '限免'
          },
          {
            imgId: '25',
            name: '滚蛋吧，负能量',
            type: '打击负能量',
            price: '102',
            free: '免费'
          },
          {
            imgId: '29',
            name: '数字谜盘',
            type: '数字谜盘',
            price: '99',
            free: '限免'
          },
          {
            imgId: '30',
            name: '游泳比赛',
            type: '游泳比赛',
            price: '99',
            free: '免费'
          },
          {
            imgId: '31',
            name: '接力跑挑战赛',
            type: '接力跑挑战赛',
            price: '88',
            free: '免费'
          },
          {
            imgId: '32',
            name: '欢乐娃娃机',
            type: '欢乐娃娃机',
            price: '99',
            free: '免费'
          },
          {
            imgId: '33',
            name: '点球大战',
            type: '点球',
            price: '110',
            free: '免费'
          },
          {
            imgId: '27',
            name: '小小扭蛋，感恩有礼',
            type: '扭蛋',
            price: '108',
            free: '免费'
          }
        ],
        7: [ // 现场活动
          {
            imgId: '24',
            name: '现场抽奖',
            type: '现场抽奖',
            price: '25',
            free: '限免'
          }
        ],
        8: [ // 节日
          {
            imgId: '22',
            name: '温暖回家路',
            type: '温暖回家路',
            price: '75',
            free: '免费'
          },
          {
            imgId: '16',
            name: '乐集卡牌',
            type: '集卡',
            price: '72',
            free: '限免'
          },
          {
            imgId: '19',
            name: '竞猜赢大奖',
            type: '足球竞猜',
            price: '99',
            free: '限免'
          },
          {
            imgId: '14',
            name: '好运一整年',
            type: '红包雨',
            price: '40',
            free: '免费'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/template.scss";
</style>

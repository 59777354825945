var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template_wrap" },
    [
      _c("div", { staticClass: "banner" }, [
        _c("div", { staticClass: "tab-wrap" }, [
          _c(
            "div",
            {
              staticClass: "tab",
              class: { choose: _vm.tabIndex == 0 },
              on: {
                click: function($event) {
                  _vm.tabIndex = 0
                }
              }
            },
            [_c("div", { staticClass: "icon" }), _vm._v("热门模板 ")]
          ),
          _c(
            "div",
            {
              staticClass: "tab",
              class: { choose: _vm.tabIndex == 1 },
              on: {
                click: function($event) {
                  _vm.tabIndex = 1
                }
              }
            },
            [_c("div", { staticClass: "icon" }), _vm._v("节日推荐 ")]
          )
        ]),
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabIndex == 0,
                  expression: "tabIndex == 0"
                }
              ],
              staticClass: "template-list"
            },
            _vm._l(_vm.hotTemplate, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "hotTemplate",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("OwnWorkbench/OwnActivity")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "template-info" }, [
                    _c("div", { staticClass: "template-name" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c("div", { staticClass: "template-price" }, [
                      _c("span", { staticClass: "free" }, [
                        _vm._v(_vm._s(item.free))
                      ]),
                      _c("span", { staticClass: "price" }, [
                        _vm._v("￥" + _vm._s(item.price) + "元")
                      ]),
                      _c("span", { staticClass: "type" }, [
                        _vm._v(_vm._s(item.type))
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabIndex == 1,
                  expression: "tabIndex == 1"
                }
              ],
              staticClass: "template-list"
            },
            _vm._l(_vm.holidayTemplate, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "hotTemplate holidayTemplate",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("OwnWorkbench/OwnActivity")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "template-info" }, [
                    _c("div", { staticClass: "template-name" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c("div", { staticClass: "template-price" }, [
                      _c("span", { staticClass: "free" }, [
                        _vm._v(_vm._s(item.free))
                      ]),
                      _c("span", { staticClass: "price" }, [
                        _vm._v("￥" + _vm._s(item.price) + "元")
                      ]),
                      _c("span", { staticClass: "type" }, [
                        _vm._v(_vm._s(item.type))
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "classify-wrap" }, [
        _c(
          "div",
          { staticClass: "classify-content" },
          [
            _vm._v(" 类别 "),
            _vm._l(_vm.classList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "classify",
                  class: { choose: _vm.classIndex == index },
                  on: {
                    click: function($event) {
                      _vm.classIndex = index
                    }
                  }
                },
                [_vm._v(_vm._s(item))]
              )
            })
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "templateList" },
        _vm._l(_vm.template[_vm.classIndex], function(item) {
          return _c(
            "div",
            {
              key: item.imgId,
              staticClass: "hotTemplate",
              style: {
                backgroundImage:
                  "url(https://wxyx-xcx.obs.cn-south-1.myhuaweicloud.com/lfPC/template/" +
                  item.imgId +
                  ".png)"
              },
              on: {
                click: function($event) {
                  return _vm.$router.push("OwnWorkbench/OwnActivity")
                }
              }
            },
            [
              _c("div", { staticClass: "template-info" }, [
                _c("div", { staticClass: "template-name" }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _c("div", { staticClass: "template-price" }, [
                  _c("span", { staticClass: "free" }, [
                    _vm._v(_vm._s(item.free))
                  ]),
                  _c("span", { staticClass: "price" }, [
                    _vm._v("￥" + _vm._s(item.price) + "元")
                  ]),
                  _c("span", { staticClass: "type" }, [
                    _vm._v(_vm._s(item.type))
                  ])
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("Foot")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }